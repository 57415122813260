:root {
  --select-border: #777;
  --select-focus: blue;
  --select-arrow: var(--select-border);
}

/* Start Sidebar */
.sidebarHeader {
  height: 40px;
  width: 100%;
  padding: 0.5rem;
  background-color: rgba(241, 241, 241, 0.861);
  border-bottom: 1px solid rgb(217, 217, 217);
  /* position: relative; */
}

.flex {
  display: flex;
  align-items: center;
}

.sidebarAvatar {
  width: 25px;
  height: 25px;
  border-radius: 50%;
  background-color: black;
  display: grid;
  place-items: center;
}

/* link */
.linkContainer {
  flex: 1;
  padding: 0.5rem;
  color: rgba(0, 0, 0, 0.7);
}

.linkItem {
  /* flex: 1; */
  border-radius: 5px;
  padding: 0.45rem;
  position: relative;
  cursor: pointer;
  margin-bottom: 8px;
  padding-left: 10px;
  text-decoration: none;
  color: rgba(0, 0, 0, 0.7);
}
.linkItemActive {
  background-color: rgba(220, 220, 220, 0.431);
  border-radius: 5px;
  padding: 0.45rem;
  position: relative;
  cursor: pointer;
  margin-bottom: 8px;
  padding-left: 10px;
}

.linkItem:hover {
  background-color: rgba(220, 220, 220, 0.73);
}

.linkItemActive::after {
  content: "";
  position: absolute;
  left: 0%;
  top: 50%;
  transform: translateY(-50%);
  width: 3px;
  height: 70%;
  background-color: rgb(11, 14, 48);
  border-radius: 20px;
}

/* End Sidebar*/

/* Start Button */
.Button {
  all: unset;
  padding: 0.5rem 0.7rem;
  background-color: #428fdc;
  font-size: 14px;
  color: #fff;
  border-radius: 5px;
  font-weight: 600;
  cursor: pointer;
  border: 1px solid rgba(217, 217, 217, 0.707);
}

.Button:active:not(:disabled) {
  transform: translateY(1px);
}

.Button:focus {
  outline: 2.5px solid rgb(236, 236, 236);
}

.Button:hover {
  background-color: #2679cb;
}

.Button:disabled {
  opacity: 0.7;
  /* cursor: progress; */
  cursor: not-allowed;
}

.ButtonLoader {
  width: 30px;
  height: 30px;
  border: 2px solid #fff;
  border-bottom-color: transparent;
  border-radius: 50%;
  display: inline-block;
  box-sizing: border-box;
  animation: rotation 1s linear infinite;
}
@keyframes rotation {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
/* End Button*/

/* Start ProjetPage */
.projetPageContainer {
  color: gray;
}

.tabPage {
  flex: 1;
  border-bottom: 1px solid rgba(217, 217, 217, 0.707);
  /* background-color: #2679cb; */
  min-height: 45px;
  display: flex;
  align-items: center;
  flex: 1;
  padding-right: 10px;
}

.tabPageItem {
  display: grid;
  place-items: center;
  height: 45px;
  padding: 0.3rem 1rem;
  font-weight: 500;
  font-size: 13.5px;
  cursor: pointer;
}

.tabPageItemActive {
  display: grid;
  place-items: center;
  height: 45px;
  padding: 0.3rem 1rem;
  font-weight: 500;
  font-size: 13.5px;
  border-bottom: 2.5px solid rgb(191, 191, 191);
  cursor: pointer;
}

.tabPageItem:hover {
  border-bottom: 2px solid rgb(191, 191, 191);
}

.tableRow {
  min-height: 70px;
  display: flex;
  align-items: center;
  cursor: pointer;
  padding: 1rem;
  border-bottom: 1px solid rgba(217, 217, 217, 0.707);
}

.tableRow:hover,
.rowHover:hover {
  background-color: #f2f2f2a9;
}
.tableAvatar {
  width: 40px;
  height: 40px;
  border: 1px solid rgba(217, 217, 217, 0.707);
  border-radius: 5px;
  display: grid;
  place-items: center;
  font-size: 1.5rem;
  font-weight: 600;
}

/* End ProjetPage*/

/* Start  Input*/
.input {
  all: unset;
  display: flex;
  flex: 1;
  flex-grow: 1;
  border: 1px solid rgba(217, 217, 217, 0.707);
  border-radius: 5px;
  font-size: 14px;
  /* padding: 0.555rem 1rem; */
  height: 30px;
  cursor: auto;
}

.input::placeholder {
  color: rgb(191, 191, 191);
}

.input:focus {
  outline: 3px solid rgb(236, 236, 236);
}

.inputSelect {
  all: unset;
  border: 1px solid rgba(217, 217, 217, 0.707);
  border-radius: 5px;
  font-size: 14px;
  height: 30px;
  /* padding: 0.555rem 1rem; */
  flex-grow: 1;
  display: flex;
  align-items: center;
  flex: 1;
  cursor: auto;
}

.inputSelect::placeholder {
  color: rgb(191, 191, 191);
}

.inputSelect:focus {
  outline: 3px solid rgb(236, 236, 236);
}

/* End Input*/

/* Start  Badge*/
.badge {
  padding: 0.2rem 0.5rem;
  border-radius: 5px;
  background-color: rgb(236, 236, 236);
  border: 1px solid rgba(217, 217, 217, 0.707);
  cursor: pointer;
  opacity: 0.8;
}

.badge:hover {
  /* background-color: rgb(210, 210, 210); */
  opacity: 1;
}
/* End Badge*/

/* Start Table */
.tableContainer {
  /* background-color: blue; */
  flex: 1;
  width: 100%;
  /* overflow: hidden; */
  padding: 0;
  border-collapse: collapse;
  border: 1px solid red;
  /* border-radius: 10px; */
}

thead {
  background-color: rgba(217, 217, 217, 0.707);
  height: 30px;
  border-radius: 10px;
}

th,
td {
  border: 1px solid rgba(217, 217, 217, 0.707);
  border-collapse: unset;
  text-align: left;
  padding: 0.5rem;
}

td {
}
/* End Table */
/* Start  */
/* End */
/* Start  */
/* End */
/* Start  */
/* End */
/* Start  */
/* End */
/* Start  */
/* End */
/* Start  */
/* End */
/* Start  */
/* End */
