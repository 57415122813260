:root {
  --primary-color: #ffffff;
  --secodary-color: #4e73df;
}

a.active {
  border-right: 5px solid white;
  background-color: rgba(255, 255, 255, 0.2);
}
.text-error {
  color: red;
  font-size: 0.8rem;
  margin-top: 0.5rem;
  font-weight: 300;
}
.add-btn {
  background-color: var(--secodary-color) !important;
  color: var(--primary-color) !important;
}
/* button[type="submit"]:active,
input[type="submit"]:active {
  transition: 0.3s all !important;
  transform: translateY(3px) !important;
  border: 1px solid transparent !important;
  opacity: 0.8 !important;
} */

/* ******************** modal ********************** */
.modal-container {
  width: 100vw;
  height: 100vh;
  background-color: #0000004d;
  position: fixed;
  left: 0%;
  top: 0%;
  z-index: 99;
  display: flex;
  align-items: center;
  justify-content: center;
}

.modal-container .modal-children {
  top: 0;
  flex: 1;
  max-width: 650px;
  max-height: 95dvh;
  background-color: #fff;
  border-radius: 5px;
  margin: 0 10px;
  overflow-y: auto;
  position: relative;
}

/* .modal-container .modal-children::-webkit-scrollbar {
  display: none;
} */

.modal-container > .modal-children > .modal-header-container {
  background-color: #fff;
  padding: 10px;
  position: sticky;
  top: 0;
  border-bottom: 1px solid rgba(128, 128, 128, 0.123);
}

.modal-container > .modal-children > .modal-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: nowrap;
}

.modal-container > .modal-children::-webkit-scrollbar {
  display: none;
}

.modal-container > .modal-children > .modal-header > .modal-close-icon {
  padding: 0 10px;
  margin-left: 10px;
  border-radius: 5px;
  color: gray;
  cursor: pointer;
}

.modal-container > .modal-children > .modal-main-container {
  /* background-color: blue; */
  padding: 10px;
}

.hacbou-select-container::-webkit-scrollbar {
  display: none;
}

/* *********************Start Template************************* */

.interface-container {
  width: 100vw;
  height: 100vh;
  /* display: flex;
    flex-direction: column; */
}

.interface-container > .navbar-container {
  /* flex: 1; */
  /* position: fixed; */
  top: 0;
  left: 0;
  width: 100%;
  height: 300px;
  z-index: 4;
}

.nav-link {
  font-weight: 500 !important;
  font-size: 0.9rem !important;
}

.interface-container > .body-container {
  /* flex: 12; */
  /* display: flex;    */
}

.interface-container > .body-container > .sidebar-container {
  height: 100vh;
  position: fixed;
  /* top: 50px; */
  left: 0%;
  background-color: #fff;
  transition: width 0.3s;
  /* z-index: 2; */
}

.interface-container .menu-controller {
  position: absolute;
  top: 5px;
  right: -10px;
  width: 25px;
  height: 25px;
  background-color: #fff;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 2px solid var(--secodary-color);
  cursor: pointer;
  z-index: 3;
}

.interface-container
  > .body-container
  > .sidebar-container
  > .menu-controller:hover {
  background-color: var(--primary-color);
}

.interface-container
  > .body-container
  > .sidebar-container
  > .menu-controller:hover
  > .menu-controller-icon {
  color: var(--secodary-color);
}

.sidebar-container-self::-webkit-scrollbar {
  display: none;
}

.interface-container > .body-container > .children-container {
  width: 100%;
  height: 100vh;
  background-color: rgba(241, 241, 241, 0.324);
  transition: padding 0.3s;
  overflow-x: hidden;
  z-index: 1;
}

/* *********************End Template************************* */

.react-bootstrap-table {
  overflow-x: scroll !important;
}
table {
  /* table-layout: fixs */
}
table > thead {
  /* vertical-align: bottom; */
  /* background-color: var(--secodary-color); */
  /* color: white;
  width: 100%; */
}
table thead th {
  /* font-weight: 500;
  padding: 1rem 1rem !important; */
}
tbody > tr {
  /* border: 1px solid gray; */
  /* border-top: none;
  border-left: none;
  border-right: none; */
  /* padding: 1.5rem 12rem !important;
  background-color: white; */
}
td {
  /* background-color: red; */
  /* padding: 0.95rem !important; */
  /* font-weight: 400; */
  /* border: none !important; */
  /* word-wrap: break-word; */
  /* white-space: nowrap; */
}
td:nth-child(2n) {
  /* border-left: 1px solid rgb(183, 180, 180) !important;
  border-right: 1px solid rgb(183, 180, 180) !important; */
}
td:last-child {
  /* border-right: none !important; */
}

/* ***************** filter btn ******************* */
.filter-btn {
  max-width: 500px;
  padding-left: 50px;
}
.search-icon {
  position: absolute;
  left: 28px;
  top: 50%;
  transform: translate(-50%, -50%);
}
/* ******************** Pagination ****************** */
.react-bootstrap-table-pagination {
  background-color: #efeded;
  padding: 0.3rem 0.5rem;
}
.react-bootstrap-table-page-btns-ul {
  justify-content: flex-end;
  margin: 0;
}
#pageDropDown {
  background-color: var(--secodary-color) !important;
  color: white !important;
  border: none;
  padding: 0.5rem 1.5rem !important;
}
.dropdown-menu {
  width: 20px !important;
}
.dropdown-item a {
  text-decoration: none;
  color: var(--secodary-color);
}
.page-link {
  color: var(--secodary-color) !important;
  padding: 0.5rem 1.5rem !important;
}
.active.page-item .page-link {
  background-color: var(--secodary-color) !important;
  text-decoration: none;
  border: none;
  color: white !important;
  /* font-size: 2rem !important; */
}
