.button-container {
    position: relative;
    display: inline-block;
  }
  
  /* .button-with-loader {
    position: relative;
    padding: 10px 20px;
    font-size: 16px;
    background-color: #007bff;
    color: #fff;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    overflow: hidden;
  } */
  
  /* .button-with-loader:disabled {
    background-color: #6c757d;
    cursor: not-allowed;
  } */
  
  .loader {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
  